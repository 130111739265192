import {
  ref, onMounted, getCurrentInstance
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { reportingDetailUseCase, reportingUseCase } from '@/domain/usecase'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import jexcel from 'jspreadsheet-ce'
import VGrid from '@revolist/vue3-datagrid'
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxSearchPanel,
  DxFilterRow,
  DxExport,
  DxColumnChooser,
  DxGrouping,
  DxGroupPanel
} from 'devextreme-vue/data-grid'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { exportDataGrid } from 'devextreme/excel_exporter'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import DxSelectBox from 'devextreme-vue/select-box'
import DxCheckBox from 'devextreme-vue/check-box'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  components: {
    VGrid,
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxSearchPanel,
    DxFilterRow,
    DxSelectBox,
    DxCheckBox,
    DxExport,
    DxColumnChooser,
    DxGrouping,
    DxGroupPanel
  },
  setup() {
    const app = getCurrentInstance()
    const { $toast, $strInd } = app!.appContext.config.globalProperties
    const dt = ref()
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const data = ref([]) as any
    const columns = ref({}) as any
    const params = ref([]) as any
    const dateStart = ref(null)
    const dateEnd = ref(null)
    const docs = ref(null) as any
    const showFilter = ref(false)
    const showParams = ref([])
    const showValueRefFalse = ref([]) as any
    const dataGridRefName = ref('dataGrid')
    const arrayLength = ref(0)
    const jExcelOptions = ref({
      data: data.value,
      hideIndex: true,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowDeleteRow: false,
      allowInsertRow: false,
      contextMenu: false,
      editable: false,
      columns: [
        {
          name: 'Min',
          title: 'Min',
          // type: 'number',
        },
        {
          name: 'Max',
          title: 'Max',
          // type: 'number',
        },
        {
          name: 'Volume',
          title: 'm3',
          // type: 'number',
        },
        {
          name: 'HargaDd',
          title: 'D-D',
          // type: 'number',
        },
        {
          name: 'HargaAd',
          title: 'A-D',
          // type: 'number',
        },
        {
          name: 'HargaDa',
          title: 'D-A',
          // type: 'number',
        },
        {
          name: 'HargaAa',
          title: 'A-A',
          // type: 'number',
        },
      ],
      columnSorting: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onchange: (instance: any, cell: any, x: any, y: any, value: any) => { },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onselection: (instance: any, cell: any, x: any, y: any, value: any) => { }
    })
    const jExcelObj = ref(null) as any
    const filtersTable = ref({}) as any
    const applyFilterTypes = ref([
      {
        key: 'auto',
        name: 'Immediately'
      },
      {
        key: 'onClick',
        name: 'On Button Click'
      }])
    const currentFilter = ref(applyFilterTypes.value[0].key)

    const initExcel = () => {
      docs.value = document.getElementById('spreadsheet')
      jExcelObj.value = jexcel(docs.value, jExcelOptions.value, 'visible', false)
      jExcelObj.value.hideIndex()
      jExcelObj.value.setData((data.value.length > 0) ? data.value : [{}])
      // jExcelObj.value.onchange = onTableChange
    }

    const initFiltersTable = (result: any) => {
      if (result.length > 0) {
        Object.keys(result[0]).forEach((index: any) => {
          filtersTable.value[index] = {
            operator: FilterOperator.AND,
            constraints: [
              {
                value: null, matchMode: FilterMatchMode.EQUALS
              },
            ]
          }
        })
      }
    }

    const checkInput = () => showParams.value.filter((item: any) => item.Value === '' || item.Value === null).length > 0

    const mappingSendParams = (val: any) => {
      const obj = {} as any
      val.forEach((data: any) => {
        obj[data.Name] = data.Value
      })
      return obj
    }

    const showDataExcel = async () => {
      if (checkInput()) {
        $toast.add({
          severity: 'error', detail: 'Semua data input harus diisi', group: 'bc', life: 1500
        })
        return
      }
      store.dispatch('showLoading')
      const senderData = mappingSendParams(showParams.value)
      const response = await reportingUseCase.generateReport(route.params.id, { params: senderData })
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        initFiltersTable(response.result.Data)
        /* eslint-disable-next-line prefer-destructuring */
        const result = response.result.Data
        data.value = result

        if (data.value.length > 0) {
          /* eslint-disable-next-line prefer-destructuring */
          columns.value = JSON.parse(JSON.stringify(result))
          /* eslint-disable-next-line arrow-body-style */
          columns.value.map((x: any) => {
            delete x.No
            return x
          })
        }
      }
      store.dispatch('hideLoading')
    }

    const checkShowFilter = () => {
      showParams.value = params.value.map((item: any) => {
        item.Value = ''
        return item
      })
      showFilter.value = showParams.value.length > 0

      if (!showFilter.value) {
        showDataExcel()
      }
    }

    const getAllData = async () => {
      const response = await reportingUseCase.getReportParams(route.params.id)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
        router.go(-1)
      } else {
        params.value = response.result
        checkShowFilter()
      }
    }

    const downlaodExcel = () => {
      dt.value.exportCSV()
    }

    const onExporting = (e: any) => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Employees')

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${route.query.title}.xlsx`)
        })
      })
      e.cancel = true
    }

    onMounted(() => {
      getAllData()
    })

    return {
      dt,
      data,
      params,
      dateStart,
      dateEnd,
      columns,
      showFilter,
      showParams,
      dataGridRefName,
      filtersTable,
      currentFilter,
      applyFilterTypes,
      onExporting,
      downlaodExcel,
      showDataExcel,
      arrayLength
    }
  }
}
